:root {
    //Current Theme Values
    --color-theme: hsl(190, 37%, 51%);
    --color-dark: hsl(from var(--color-theme) h s calc(l - 40));
    --color-light: hsl(from var(--color-theme) h s calc(l + 40));
    --color-site-background: hsl(from var(--color-theme) h calc(s - 10) calc(l + 50));
    --desaturated-hue: 50;
    --desaturated-sat: 7%;
    --color-lightness-shift-basis: 1;
    //Derivative values

    --color-fill-inverse: var(--color-light);
    --color-fill-neutral: var(--color-dark);
    --color-fill-theme: var(--color-theme);
    --color-fill-theme-highlight: hsl(from var(--color-fill-theme) h s calc(l + 15));

    --color-fill-positive: hsl(130, 69%, 45%);
    --color-fill-negative: hsl(2, 100%, 50%);
    --color-fill-alert: hsl(25, 100%, 50%);

    --color-text-inverse: var(--color-fill-neutral);

    --color-text-neutral: var(--color-fill-inverse);

    --color-text-theme: hsl(from var(--color-fill-theme) h s calc(l - 30));

    --color-text-negative: hsl(from var(--color-fill-negative) h s calc(l - 30));

    // Mana Colors
    --color-white-mana: hsl(56, 69%, 91%);
    --color-blue-mana: hsl(215, 49%, 68%);
    --color-black-mana: hsl(275, 21%, 63%);
    --color-red-mana: hsl(19, 66%, 68%);
    --color-green-mana: hsl(100, 55%, 43%);
    --color-colorless-mana: hsl(30, 8%, 77%);
}

//Safari Specific Values
.safari {
    --color-dark: hsl(190, 37%, 11%);
    --color-light: hsl(190, 37%, 91%);
    --color-site-background: hsl(190, 27%, 101%);
    --color-lightness-shift-basis: 1;
    --color-text-theme: hsl(190, 37%, 21%);
    --color-text-neutral: hsl(190, 37%, 11%);
    --color-text-neutral: hsl(190, 37%, 91%);
    --color-text-negative: hsl(2, 100%, 20%);
    --color-fill-theme-highlight: hsl(190, 37%, 66%);
    --color-fill-inverse: hsl(190, 37%, 91%);
    --color-fill-neutral: hsl(190, 37%, 11%);
}
