.trades-table {
    .row {
        @media only screen and (max-width: 850px) {
            flex-direction: column;
            &--headings {
                display: none;
            }
        }
        .cell:first-child {
            @media only screen and (max-width: 850px) {
                color: var(--color-text-neutral);
                background: var(--color-fill-neutral);
                width: 100%;
            }
        }
    }

    .cell {
        @media only screen and (max-width: 850px) {
            text-align: center;

            .fa-times {
                position: relative;
                top: 3px;
            }
        }
    }

    &-cell {
        &__date-status {
            flex-basis: 15%;
            display: flex;
            flex-direction: column;
            align-items: center;

            i {
                font-size: 1.5rem;

                &.approved {
                    color: var(--color-fill-positive);
                }
                &.rejected {
                    color: var(--color-fill-negative);
                }
                &.error {
                    color: var(--color-fill-negative);
                }
            }
        }

        &__card-list {
            flex-basis: 25%;
        }

        &__name {
            flex-basis: 20%;
        }
    }

    p {
        text-transform: uppercase;
    }

    .review-button {
        @media only screen and (max-width: 650px) {
            i {
                font-size: 0.8rem;
            }
        }
    }
}

.trade-review-modal {
    &__contents {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        width: 75vw;
        @media only screen and (max-width: 850px) {
            flex-direction: column;
        }
    }

    &__table {
        flex: 0 0 50%;
        min-width: 0;
        height: fit-content;

        &:first-of-type {
            & .cell--card {
                text-align: left;
                @media only screen and (max-width: 850px) {
                    text-align: center;
                }
            }
            & .cell--rarity {
                order: 3;
            }
            & .cell--arrow {
                margin-right: 0.25rem;
                order: 1;
            }
        }

        &:last-of-type {
            & .cell--card {
                text-align: right;
                @media only screen and (max-width: 850px) {
                    text-align: center;
                }
            }
            & .cell--rarity {
                order: 1;
            }
            & .cell--arrow {
                margin-left: 0.25rem;
                order: 3;
            }
        }

        .cell--card {
            flex-grow: 1;
            flex-shrink: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            order: 2;
        }

        .cell--rarity {
            font-weight: 850;
            &.mythic {
                color: #f5661b;
            }
            &.rare {
                color: #d4af37;
            }
            &.uncommon {
                color: #838996;
            }
            &.common {
                color: var(--color-text-inverse);
            }
        }
    }
}
